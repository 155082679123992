import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useState } from 'react'

import { Box, Checkbox, useMediaQuery } from '@material-ui/core'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import PhoneIcon from '@material-ui/icons/Phone'
import clsx from 'clsx'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { CustomButton } from '../../CustomButton'
import { useTheme } from '@material-ui/core/styles'
import { Image } from '../../Images'
import api from '../../../utils/api'
import { toast } from 'react-toastify'
import { handleLeadSubmission, sendLeadEmail } from '../../../utils/helper'
import { EMAIL_SUPPORT } from '../../../utils/constants'
const Square = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #606060;
  margin: 2px;
  background: transparent;
`
const FullSquare = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #606060;
  margin: 2px;
  background: #979797;
`
const SquareRed = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid;
  margin: 2px;
  background: transparent;
`

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '408px',
    paddingBlock: theme.spacing(5),
    backgroundColor: '#FFF',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.between('xs', 'md')]: {},
    [theme.breakpoints.between('xs', 'sm')]: {
      width: 'unset',
    },
  },
  innerDiv: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    padding: '0em 1em',
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexBasis: 'unset',
      width: '100%',
      padding: '0em 0em',
    },
  },

  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '38.75px',
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 400,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(35),
    },
  },
  subtitle: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    lineHeight: '15.47px',
    marginBottom: theme.spacing(5),
  },
  detail: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  message: {
    fontFamily: `'Gotham Light', serif`,
    marginTop: '1.5rem',
    paddingBottom: '0',
    fontWeight: 325,
    fontSize: '12px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      // marginInline: '24px',
    },
  },
  ml1: {
    marginLeft: '1em',
  },
  linksText: {
    fontFamily: `'Gotham Light', serif`,
    fontSize: '14px',
    fontWeight: 325,
    lineHeight: '16.8px',
    color: theme.palette.text.primary,
    textDecoration: 'unset',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  containerClass: {
    marginBottom: 30,
    flex: 1,
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  w100: {
    width: '100%',
  },
  mb2: {
    marginInline: '24px',
    marginBottom: theme.spacing(10),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginInline: '0',
    },
  },
  textareaMessage: {
    width: '100%',
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingInline: '24px',
    },
  },

  inputClass: {
    '& input': {
      fontFamily: `'Gotham Book', serif`,
      fontSize: '.8rem',
      color: '#818181',
    },
    '&::placeholder': {
      fontFamily: "'Gotham Light', serif",
      fontSize: '12px',
      fontWeight: 325,
      lineHeight: '14.4px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: theme.palette.primary,
    },
    '& .MuiInputBase-input': {
      fontFamily: `'Gotham Book', serif`,
      fontSize: '.8rem',
      color: '#818181',
    },
    '& .MuiInputBase-input::placeholder': {
      fontFamily: `'Gotham Light', serif`,
      fontSize: '12px',
      fontWeight: 325,
      color: '#818181',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #000', // Ligne sous l'input
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #000', // Ligne sous l'input au survol
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid #000', // Ligne active
    },
  },
  inputPlaceholder: {
    color: '#fff',
    '&::placeholder': {
      fontFamily: "'Gotham Light', serif",
      fontSize: '12px',
      fontWeight: 325,
      lineHeight: '14.4px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: theme.palette.primary,
    },
    '&.MuiInputBase-input::placeholder': {
      fontFamily: `'Gotham Light', serif`,
      fontSize: '12px',
      fontWeight: 325,
      lineHeight: '14.4px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: theme.palette.primary,
    },
  },
  btnSection: {
    justifyContent: 'center',
    marginBlock: theme.spacing(5),
    paddingInline: '24px',
  },
  font: {
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(1),
  },
  imgProfile: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '-8%',
  },
  agentDetails: {
    // marginTop: '50px',
  },
  agentName: {
    fontFamily: `'Gotham Book', serif`,
    fontWeight: 700,
    fontSize: '14px',
    color: '#1D1D1B',
    paddingBlock: '16px 12px',
  },
  agentPhone: {
    fontFamily: `'Gotham Light', serif`,
    fontWeight: 325,
    fontSize: '12px',
    color: '#1D1D1B',
    marginBottom: '8px',
  },
  agentJob: {
    fontFamily: `'Gotham Light', serif`,
    fontWeight: 325,
    fontSize: '12px',
    color: '#808080',
  },
}))

const ContactAgentForm = ({ agent, reference, country, emailTo, link, address }: { agent: any; reference: any; country: any, emailTo: any, link: any, address: any }) => {
  const classes = useStyles()
  const intl = useIntl()
  const theme = useTheme()
  const isSMDown = useMediaQuery(theme.breakpoints.down('sm'))
  const [error, setError] = useState(['initial'])
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    form: 'FE1',
    consent: false,
  }
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    form: 'FE1',
    consent: false,
  } as any)

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string
        value: unknown
        textContent?: any
        checked?: boolean
      }>,
    ) => {
      if (event && event.target && (event.target.name || event.target.textContent)) {
        setError([])
        setState({
          ...state,
          [event.target.name || ('adresse' as string)]:
            (event.target.name == 'consent' ? event.target.checked : event.target.value) ||
            event.target.textContent,
        })
      }
    },
    [state],
  ) as any

  const onSubmit = async () => {
    const { message, consent, ...keyToValidate } = state
    const pays = country === "Espagne" ? "ES" : ""

    const validateField = Object.keys(keyToValidate).filter((key: string) => {
      return !state[key] // Retourne les champs vides
    })

    if (validateField.length) {
      setError(validateField)
    } else {
      const detailedMesg = `
            consent : ${state.consent ? true : false} , \n
            message : ${state.message} , \n
            `
      const payload = {
        simpleLead: {
          listingId: `${reference}`,
          source: 'Kretz',
          contactFullName: state.firstName + ' ' + state.lastName,
          contactPhoneNumber: state.phone,
          contactEmail: state.email,
          message: detailedMesg,
        },
      }

      try {
        // Handle lead submission using helper method
        const leadResult = await handleLeadSubmission(payload);
        
        if (leadResult.success) {
          setError([])
          setState(initialValues)
          toast.success(intl.formatMessage({ id: 'new.toast_ok' }), {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
          });

          // Prepare email message
          // Prepare email subject with property reference
          const emailSubject = `🔔 Buyer Inquiry Ref: ${reference}`;


          // Format email in English
          const formattedEmailMsg = `
          Hello ${agent?.name},

          ✅ You have a buyer inquiry for property ${reference} ${link}, located at ${address}.

          👉 Here are the details of the request:
                <b>First Name:</b> ${state.firstName}
                <b>Last Name:</b> ${state.lastName}
                <b>Phone:</b> ${state.phone}
                <b>Email:</b> ${state.email}
                <b>Message:</b> ${state.message}

          Please contact this person as soon as possible.

          Best regards,
          The Support Team
          `;


          // Send email using helper method
          const emailResult = await sendLeadEmail(
            formattedEmailMsg,
            emailSubject,
            emailTo,
            EMAIL_SUPPORT
            //    recipientEmail:
          );

          if (!emailResult.success) {
            console.log("Email sending failed:", emailResult.error);
          }
        } else {
          throw new Error(leadResult.error?.message || 'Lead submission failed');
        }
      } catch (error) {
        toast.error(intl.formatMessage({ id: 'new.toast_error' }), {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
        });
      }
    }
  }

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.innerDiv}>
        <Grid container xs={12} className={classes.imgProfile}>
          {agent?.photo?.portraitSquare &&
            !agent.photo.portraitSquare.includes('undefined') &&
            agent.photo.portraitSquare.trim() !== '' ? (
            <img
              src={agent.photo.portraitSquare}
              alt="Profile"
              style={{
                borderRadius: '50%',
                width: '100px',
                height: '100px',
                overflow: 'hidden',
              }}
              loading="lazy"
            />
          ) : (
            <Image
              src="avatar_default.png"
              alt="Default Profile"
              layout="CONSTRAINED"
              className="slide-img"
              style={{
                borderRadius: '50%',
                width: '150px',
                height: '150px',
                overflow: 'hidden',
              }}
            />
          )}
        </Grid>
        <Grid container xs={12} className={classes.agentDetails}>
          <Grid item xs={12} className={classes.agentName}>
            {agent.name}
          </Grid>
          <Grid item xs={12} className={classes.agentPhone}>
            <PhoneIcon className={classes.font} />
            {agent.phone}
          </Grid>
          <Grid item xs={12} className={classes.agentJob}></Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid className={classes.title}>
            <span>{intl.formatMessage({ id: 'new.Formulaire contact spe biens' })}</span>
          </Grid>
          <Grid className={classes.subtitle}>
            <span>{intl.formatMessage({ id: 'new.Formulaire contact spe biens_2' })}</span>
          </Grid>
        </Grid>
        <Grid container xs={12} md={12} className={classes.detail} spacing={6}>
          <Grid item lg={6} xs={12}>
            <TextField
              variant="standard"
              placeholder={intl.formatMessage({ id: 'firstName' })}
              name="firstName"
              value={state.firstName}
              error={error.includes('firstName')}
              onChange={handleChange}
              InputProps={{
                classes: {
                  input: classes.inputPlaceholder,
                },
              }}
              className={clsx(classes.w100, classes.inputClass)}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              variant="standard"
              placeholder={intl.formatMessage({ id: 'yourName' })}
              name="lastName"
              value={state.lastName}
              error={error.includes('lastName')}
              onChange={handleChange}
              InputProps={{
                classes: {
                  input: classes.inputPlaceholder,
                },
              }}
              className={clsx(classes.w100, classes.inputClass)}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              value={state.phone}
              variant="standard"
              placeholder={intl.formatMessage({ id: 'yourNumber' })}
              name="phone"
              error={error.includes('phone')}
              onChange={handleChange}
              InputProps={{
                classes: {
                  input: classes.inputPlaceholder,
                },
              }}
              className={clsx(classes.w100, classes.inputClass)}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              variant="standard"
              placeholder={intl.formatMessage({ id: 'yourEmail' })}
              name="email"
              value={state.email}
              error={error.includes('email')}
              onChange={handleChange}
              InputProps={{
                classes: {
                  input: classes.inputPlaceholder,
                },
              }}
              className={clsx(classes.w100, classes.inputClass)}
            />
          </Grid>
          <Grid xs={12} item className={clsx(classes.message)}>
            {intl.formatMessage({ id: 'yourMessage' })}...
          </Grid>
          <Box className={clsx(classes.textareaMessage, classes.mb2)}>
            <TextareaAutosize
              className={clsx(classes.transparent, classes.w100)}
              aria-label="your message"
              name="message"
              value={state.message}
              onChange={handleChange}
              minRows={18}
              style={{ resize: 'none', overflow: "auto", maxHeight: "300px" }}
            />
          </Box>
        </Grid>
        <Grid container md={12} style={{ paddingInline: '1rem', textAlign: 'left' }}>
          <Grid item md={1} xs={1} style={{ textAlign: 'center' }}>
            <Checkbox
              size="small"
              icon={
                state.consent == false && !error.includes('initial') ? <SquareRed /> : <Square />
              }
              checkedIcon={<FullSquare />}
              checked={state.consent}
              onChange={handleChange}
              name={'consent'}
              style={{ padding: '0 0 0 0px' }}
            />
          </Grid>
          <Grid item md={11} xs={11}>
            {intl.formatMessage({ id: 'legalConsent.estimate.agree' })}
          </Grid>
        </Grid>
        <Grid
          container
          md={12}
          style={{ paddingInline: '1rem', paddingTop: '1rem', textAlign: 'left' }}
        >
          <Grid item md={1} xs={1} style={{ textAlign: 'center' }}></Grid>
          <Grid item md={11} xs={11}>
            {intl.formatMessage({ id: 'legalConsent.estimate.moreInformation' })}
          </Grid>
        </Grid>
        <Grid container md={12} className={classes.btnSection}>
          <CustomButton
            onClick={onSubmit}
            darkBoarder
            width={isSMDown ? '100%' : '25%'}
            textTransform="uppercase"
            isDarkBackground={true}
          >
            <span style={{ fontSize: '0.8rem', padding: '0 0px', fontWeight: 100 }}>
              {intl.formatMessage({ id: 'send' })}
            </span>
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ContactAgentForm
